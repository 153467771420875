import React, { ReactElement, useEffect, useState } from 'react';
import { usePageTheme, useApp } from '@Context/AppContext';
import SEO from '@Components/SEO';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import '@GraphQL/fragments';
import Signup from '@Src/components/Signup';
import CheckoutForm from '@Src/components/Signup/Form/checkout';
import { graphql } from 'gatsby';
export interface Props extends IPageProps {
	data: {
		contentfulPageTypeSignup: IPageData;
	};
}

const SignupPage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
	const { title, seoSettings, content } = data.contentfulPageTypeSignup;
	const { setTheme: setPageTheme } = usePageTheme();
	const { pageType, setPageType, setPageTitle, mode } = useApp();
	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

	useEffect(() => {
		if (pageType === 'index' && transitionStatus == 'exiting') {
			setLocalTransStatus(transitionStatus + 'Index');
		} else {
			setLocalTransStatus(transitionStatus);
		}

		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'dark');
			setPageTitle(title);
			setPageType(pageContext.type);
		}
	}, [transitionStatus]);
	useEffect(() => {
		if (transitionStatus == 'entered') {
			setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'dark');
			console.log("mode", mode)
			//setPageTheme(mode == 'day' ? 'yellow' : 'dark');
		}
	}, [mode]);

	return (
		<ContentLayout transitionState={localTransStatus} fullWidth type={'signup'}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/>
			<Signup>
				<CheckoutForm />
			</Signup>
      <div>
        <p>Company details:</p>
        <p>ALOADED AB<br />
        Bondegatan 21<br />
        116 33 Stockholm<br />
        Sweden<br />
        <br />Company Approved for Company Tax (Godkänd för F-skatt) <br />
        with national company registration number: 559124-3836<br />
        VAT SE559124383601
        </p>
      </div>
		</ContentLayout>
	);
};

export default SignupPage;
export const query = graphql`
	query {
		contentfulPageTypeSignup {
			... on IPageNode {
				...PageFields
			}
		}
	}
`;
