import React, { ReactElement, useMemo, useCallback, useState, FormEventHandler, useEffect } from 'react';
import axios from 'axios';
import cx from 'classnames';
import Input from '@UI/Input';
import BaseButton from '@UI/BaseButton';
import { usePageTheme, useApp } from '@Context/AppContext';	
import { loadStripe } from '@stripe/stripe-js';
import Text from '@Src/components/Text';
import { Elements, ElementsConsumer, LinkAuthenticationElement, PaymentElement } from '@stripe/react-stripe-js';
import Loader from '@Src/components/ui/Loader';
import OrderSummary from '../OrderSummary';
import Checkbox from '@Src/components/ui/Checkbox';
import Select from '@Src/components/ui/Select';
import countries from './countries';
import * as styles from './index.module.scss';
import { navigate } from 'gatsby';
// @ts-ignore
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

export interface FormData {
	email: string;
	firstname: string;
	lastname: string;
	company: string;
	selectDummy: string;
	acceptedTermsAndConditions?: boolean;
}

export interface ResponseData {
	data: {
		inlineMessage: string;
	};
}

interface Order {}

export interface Props {
	order?: {} | null;
	stripe?: any;
	elements?: any;
	onResponse?: (value: string) => void;
}


let item = {
  item_id: "Distribution+",
  item_name: "Distribution+",
  affiliation: 'ALOADED',
  currency: 'EUR',
  discount: 0.00,
  item_brand: 'ALOADED',
  item_category: 'service',
  item_category1: 'Digital',
  item_category2: 'Distribution',
  price: 250,
  quantity: 1
}


const LinkPaymentForm = ({ order, stripe, elements, onResponse }: Props): ReactElement => {
	const [postError, setPostError] = useState<boolean>(false);
	const [submit, setSubmit] = useState(false);
	const [email, setEmail] = useState<string>('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [country, setCountry] = useState('');
	const [vatNumber, setVATNumber] = useState('');
	const [labelName, setLabelName] = useState('');
	const [paymentError, setPaymentError] = useState<string|null>(null);
	const { mode } = useApp();

	const [isEmailInvalid, setInvalidEmail] = useState(false);
	const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
	const [isPaymentReady, setPaymentReady] = useState(false);
	const [isLinkAuthenticationReady, setLinkAuthenticationReady] = useState(false);
	const [isPaymentFormReady, setPaymentFormReady] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		setCanSubmit(
			acceptedTermsAndConditions
			/*&& !isEmailInvalid
      && firstName?.length > 0
      && lastName?.length > 0*/
		);
	}, [acceptedTermsAndConditions, isEmailInvalid, firstName, lastName]);

	//add localStorage into hook to avoid ssr-errors
	useEffect(() => {
		const defaultValue = localStorage.getItem('email') || '';
		setEmail(defaultValue);
    setFirstName(localStorage.getItem('firstName') || '')
    setLastName(localStorage.getItem('lastName') || '')
    setLabelName(localStorage.getItem('labelName') || '')
    setEmail(localStorage.getItem('email') || '')
	}, []);

	let submitButtonCssClass = styles.submitButton;
	if (mode == "night") {
		submitButtonCssClass = styles.submitButtonNight;
	}

	React.useEffect(() => {
		console.log('is link authentication ready', isLinkAuthenticationReady && isPaymentReady);
		console.log('is payment ready', isPaymentReady);
		console.log('is payment< form ready', isLinkAuthenticationReady && isPaymentReady);
		setPaymentFormReady(isLinkAuthenticationReady && isPaymentReady);
	}, [isLinkAuthenticationReady, isPaymentFormReady]);
	const onTermsAndConditionsToggled = (event: MouseEvent) => {
		const checkBox = event.target as HTMLInputElement;
		setAcceptedTermsAndConditions(checkBox && checkBox.checked);
	};
	const onLinkAuthenticationReady = () => {
		setLinkAuthenticationReady(true);
	};
	const onPaymentElementReady = () => {
		setPaymentReady(true);
	};
  const handleVATNumberChanged = (event: any) => {
    
  }
	const onSubmit = async (event: any) => {
		event.preventDefault();
		setPostError(false);
		setSubmit(true);
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

    // @ts-ignore
    if (window.dataLayer instanceof Array) {
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null })
      // @ts-ignore
      window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          currency: 'EUR',
          description: 'Distribution+',
          value: 250.00,
          tax: 50.00,
          shipping: 0.00,
          payment_type: 'Credit Card',
          items: [
            item
          ]
        }
      })
    }
		const port = window.location.port == '80' ? '' : ':' + window.location.port;

		const result = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: `${firstName} ${lastName}`,
            email
						//country: country // TODO Find out how to pass country with payment
					}
				},
        return_url: window.location.protocol + '//' + window.location.hostname + port + '/signup/processing'
			}
		})

		if (result.error) {
			setSubmit(false);
			setPostError(true);
			setPaymentError(result.error);
			// Show error to your customer (for example, payment< details incomplete)
			console.log(result.error.message);
      // @ts-ignore
      if (window.dataLayer instanceof Array) {
        // @ts-ignore
        window.dataLayer.push({ ecommerce: null });
        // @ts-ignore
        window.dataLayer.push({
          event: 'payment_declined',
          ecommerce: {
            transaction_id: result.id,
            currency: 'EUR',
            description: 'Distribution+',
            value: 250.00,
            tax: 50.00,
            shipping: 0.00,
            quantity: 1,
            content_type: 'product',
            content_id: 1,
            items: [
              item
            ]
          }
        })
      }
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.

      // @ts-ignore
      if (window.dataLayer instanceof Array) {
        // @ts-ignore
        window.dataLayer.push({ ecommerce: null });
        // @ts-ignore
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: result.id,
            affiliation: 'ALOADED.com',
            value: 250.00,
            tax: 50.00,
            currrency: 'EUR',
            items: [
              item
            ]
          },
          eventCallback: function () {
            navigate('/signup/processing')
          }
        })
      } else {
        navigate('/signup/processing')
      }
		}
		return false;
	};
  // @ts-ignore
  const dark = mode === 'dark' || mode === 'night'
	console.log('countries', countries);
	let tosTableCssClasses = [styles["tosTable"]];
	if (mode === 'night') {
		tosTableCssClasses.push(styles["tosTableNight"]);
	}
	console.log(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
	return (
		<div className={cx(styles.root, submit)}>
			<form onSubmit={onSubmit}>
				{isLinkAuthenticationReady && isPaymentReady ? (
					<div>
            {/* @ts-ignore */}
						<OrderSummary showTax={true} order={order} />
					</div>
				) : (
					<Loader />
				)}
       

				{/*isLinkAuthenticationReady && isPaymentReady ? (
					<>
						<div style={{ height: '43pt' }} />
						<div style={{ borderBottom: '1pt solid black' }}></div>
					</>
				) : null*/}
				<div style={{display: 'flex', alignItems: 'stretch', flexDirection: 'column', gap: 0}}>
        {true &&
          <>
            <table className={cx(styles.table, dark && styles.tableDark)} cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td style={{padding: 20}}>
                    <Input
                      label="First Name"
                      id="firstName"
                      onUpdate={(value) => setFirstName(value)}
                      value={firstName}
                      theme={mode}
                      required
                    />
                  </td>
                  <td style={{padding: 20}}>
                    <Input
                      label="Last Name"
                      id="lastName"
                      onUpdate={(value) => setLastName(value)}
                      value={lastName}
                      theme={mode}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className={styles.field}>
                    <Input
                      label="label"
                      id="label"
                      theme={mode}
                      onUpdate={(value) => setLabelName(value)}
                      value={labelName}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            </>
          }
					<LinkAuthenticationElement
            onChange={(e) => setEmail(e.value.email)}
						onReady={onLinkAuthenticationReady}
						// Optional prop for prefilling customer information
						options={{
							defaultValues: {
								email
							}
						}}
					/>
         
					{/*isLinkAuthenticationReady && isPaymentReady ? <div style={{ borderTop: '1pt solid black' }}></div> : null*/}
					<PaymentElement
						onReady={onPaymentElementReady}
            onChange={(e) => {
              console.log(e)
            }}
						// Optional prop for prefilling customer information
						options={{
							defaultValues: {
                // @ts-ignore
								email
							}
						}}
					/>
				</div>

				{isLinkAuthenticationReady && isPaymentReady ? (
					<>
						<div className={styles.field} style={{padding: 10}}>
							<Input
								label="VAT Number (optional)"
								id="vatNumber"
                // @ts-ignore
                onBlur={handleVATNumberChanged}
                theme={mode}
								onUpdate={(value) => setVATNumber(value)}
								value={vatNumber}
								placeholder="SE123456789001"
							/>
						</div>
					</>
				) : null}
				
				{isLinkAuthenticationReady && isPaymentReady ? (
					<>
						{paymentError && <Text className={styles.error} htmlText={`Payment was declined`} />}
						<table className={cx(tosTableCssClasses)}>
							<tbody>
								<tr>
									<td>
										<label htmlFor="acceptTermsAndConditions" className={styles.checkBox}>
											<Checkbox theme={mode} onUpdate={(value) => setAcceptedTermsAndConditions(value)} />
										</label>
									</td>
									<td>
										<Text
											theme={mode}
											htmlText={`I accept the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a> for an Aloaded 12 month music distribution deal.`}
										/> 
									</td>
								</tr>
							</tbody>
						</table>
						<BaseButton
							className={submitButtonCssClass}
							isLoading={submit}
							isConfirm={true}
							disabled={!canSubmit}
							buttonType="submit"
							link={{
								url: '',
								id: 'submit',
								target: '_modal',
								label: submit ? 'Submitting your order... Please wait' : 'Pay & Register',
								primary: true
							}}
						/>

						<span className={cx(styles.postError)}>
							{' '}
							{postError && <>Something went wrong sending your request. Please try again later.</>}{' '}
						</span>
						<br />
						<br />
						<br />
						<br />
					</>
				) : null}
			</form>
		</div>
	);
};

// @ts-ignore
export default function LinkForm({ onResponse, transitionStatus }: Props): ReactElement {
	const [clientSecret, setClientSecret] = useState<string>();
	const [order, setOrder] = useState(null);
	const { mode, pageType, setPageType, setPageTitle } = useApp();

	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  let query = new URLSearchParams()
  if (typeof window !== `undefined`) {
    query = new URLSearchParams(window.location.search)
  }

	useEffect(() => {
    let client_secret : string = localStorage.getItem('stripeClientSecret')!
    let query = new URLSearchParams(window.location.search)
    if (query.get('pi')) {
      client_secret = query.get('pi')!
    }
    setClientSecret(client_secret);
    let strOrder : string = localStorage.getItem('order')!
    setOrder(JSON.parse(strOrder));
	}, []);

	if (!clientSecret) {
		return (
			<div>
				<Loader />
			</div>
		);
	}
	const foreColor = mode == 'night' ? 'white' : 'black';
	const backColor = mode == 'night' ? 'black' : '#E7FD00';
	const grayColor = mode == 'night' ? '#ddd' : '#ddd';
	const appearance = {
		labels: 'floating',
		theme: mode == 'night' ? 'night' : 'none',
		rules: {
			base: {
				margin: 0
			},
			'.Label': {
				color: mode == 'night' ? 'white' : 'black',
				fontSize: '28pt'
			},
			'.Input': {
				colorTextPlaceholder: mode == 'night' ? 'gray' : '#333',
				boxShadow: 'none',
				borderBottom: '1px solid ' + foreColor,
				borderRight: '1px solid ' + foreColor,
				marginLeft: '0',
				fontFamily: 'Helvetica',
				fontSize: '1.8rem'
			},
			'.Input:focus': {
				boxShadow: 'none',
				fontFamily: 'Helvetica',
				fontSize: '1.8rem'
			}
		},
		variables: {
			spacingGridColumn: 0,
			colorTextPlaceholder: foreColor,
			colorPrimary: foreColor,
			colorBackground: backColor,
			grayColor,
			fontFamily: 'Helvetica',
			borderRadius: 0
		}
	};
	return (
    // @ts-ignore
		<Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
			<ElementsConsumer>
				{({ stripe, elements }) => ( 
					<LinkPaymentForm order={order} stripe={stripe} elements={elements} />							 
				)}
			</ElementsConsumer>
		</Elements>	
	);
}
